import { lazy } from 'react';
import { AppPlugin } from '@grafana/data';
import { locationService } from '@grafana/runtime';
const AlertingHomepageCallout = lazy(() => import('../components/Alerting/AlertingHomepageCallout'));
const ServiceSloPerformance = lazy(() => import('../extensions/service/ServiceSloPerformance'));

interface BaseRuleExtensionContext {
  name: string;
  namespace: string;
  group: string;
  expression: string;
  labels: Record<string, string>;
}

interface AlertingRuleExtensionContext extends BaseRuleExtensionContext {
  annotations: Record<string, string>;
}

interface RecordingRuleExtensionContext extends BaseRuleExtensionContext {}

enum GrafanaExtensionPoints {
  AlertingHomePage = 'grafana/alerting/home',
  AlertingAlertingRuleAction = 'grafana/alerting/alertingrule/action',
  AlertingRecordingRuleAction = 'grafana/alerting/recordingrule/action',
}

enum SloLabels {
  SloUuid = 'grafana_slo_uuid',
  DashboardUrl = 'dashboard_url',
}

function getLabelValue(context: BaseRuleExtensionContext | undefined, label: SloLabels) {
  return context?.labels?.[label];
}

export function configureGrafanaExtensions(plugin: AppPlugin) {
  plugin.configureExtensionComponent({
    extensionPointId: GrafanaExtensionPoints.AlertingHomePage,
    title: 'SLO Alerting',
    description: 'Alerting based on SLOs',
    component: AlertingHomepageCallout,
  });

  plugin.configureExtensionLink<AlertingRuleExtensionContext>({
    extensionPointId: GrafanaExtensionPoints.AlertingAlertingRuleAction,
    title: 'Edit SLO',
    description: 'Edit SLO',
    icon: 'edit',
    configure(context) {
      const sloUuid = getLabelValue(context, SloLabels.SloUuid);

      if (!sloUuid) {
        return undefined; // Returning undefined will hide the link
      }

      return {};
    },
    onClick(_, { context }) {
      const sloUuid = getLabelValue(context, SloLabels.SloUuid);

      if (!sloUuid) {
        // This should never happen. The configure function should return undefined and the link should be hidden.
        return;
      }

      locationService.push(`/a/grafana-slo-app/wizard/review/${sloUuid}`);
    },
  });

  plugin.configureExtensionLink<AlertingRuleExtensionContext>({
    extensionPointId: GrafanaExtensionPoints.AlertingAlertingRuleAction,
    title: 'SLO Dashboard',
    description: 'View SLO dashboard',
    icon: 'dashboard',
    configure(context) {
      const dashboardUrl = getLabelValue(context, SloLabels.DashboardUrl);

      if (!dashboardUrl) {
        return undefined; // Returning undefined will hide the link
      }

      return {};
    },
    onClick(_, { context }) {
      const dashboardUrl = getLabelValue(context, SloLabels.DashboardUrl);

      if (!dashboardUrl) {
        // This should never happen. The configure function should return undefined and the link should be hidden.
        return;
      }
      const url = new URL(dashboardUrl, window.location.origin);
      locationService.push({ pathname: url.pathname, search: url.search });
    },
  });

  plugin.configureExtensionLink<RecordingRuleExtensionContext>({
    extensionPointId: GrafanaExtensionPoints.AlertingRecordingRuleAction,
    title: 'Edit SLO',
    description: 'Edit SLO',
    icon: 'edit',
    configure(context) {
      const sloUuid = getLabelValue(context, SloLabels.SloUuid);

      if (!sloUuid) {
        return undefined; // Returning undefined will hide the link
      }

      return {};
    },
    onClick(_, { context }) {
      const sloUuid = getLabelValue(context, SloLabels.SloUuid);

      if (!sloUuid) {
        // This should never happen. The configure function should return undefined and the link should be hidden.
        return;
      }

      locationService.push(`/a/grafana-slo-app/wizard/review/${sloUuid}`);
    },
  });

  plugin.exposeComponent({
    id: 'grafana-slo-app/serviceDetailComponent/v1',
    component: ServiceSloPerformance,
    title: 'Service SLO Performance',
    description: 'Display SLO performance',
  });
}
